import type { SearchContextState } from "@elastic/search-ui";
import { useContext, useMemo } from "react";
import { ResultsContext, ResultsDispatchContext } from '../appReducer';

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiText, EuiBadge,
  EuiButtonIcon, EuiSplitPanel } from '@elastic/eui';

export type ResultsContainerContext = Pick<SearchContextState, "results">;

function MySavedResults() {
  const results = useContext(ResultsContext);
  const saveResultDispatcher = useContext(ResultsDispatchContext);
  const selected = useMemo(() => {
    if (results) {
      const idx = results?.findIndex((r) => r.isSelected);
      return (idx !== undefined && idx > -1) ? results?.[idx] : null
    } else {
      return null
    }
  }, [results])
  const dtFormater = new Intl.DateTimeFormat('en-UK', { dateStyle: 'short', timeStyle: 'short' });

  return (
    <EuiSplitPanel.Outer grow>
      <EuiSplitPanel.Inner grow={false} color="subdued" paddingSize="s">
        {selected && selected.responseState && <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <EuiBadge color={selected.color}>{selected.reqestState.searchTerm || `Result` }</EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
            onClick={() => saveResultDispatcher?.({
              type: 'toggleMapShow',
              idx: selected.responseState.requestId
            })}
            iconType={selected.isShown ? 'pinFilled' : 'pin'}
            aria-label="pin"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              onClick={() => saveResultDispatcher?.({
                type: 'deleted',
                idx: selected.responseState.requestId
              })}
              color="danger"
              iconType='trash'
              aria-label="trash"
              />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              onClick={() => saveResultDispatcher?.({
                type: 'select',
                idx: selected.responseState.requestId
              })}
              color="danger"
              iconType='cross'
              aria-label="cross"
              />
          </EuiFlexItem>
        </EuiFlexGroup>}
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner grow paddingSize="none" style={{overflowY: 'auto', maxBlockSize: 'calc(100vh - 100px)'}}>
        {selected && selected.responseState && selected.responseState.results.map((r, i) => <EuiSplitPanel.Outer key={r.id.raw}>
          <EuiSplitPanel.Inner grow={false} paddingSize="s">
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <span dangerouslySetInnerHTML={{__html: r.title.snippet || (r.title.raw || 'ยังไม่ระบุประเภท')}}></span>
                </EuiTitle>
                <EuiText size="xs">{ r.created_at ? dtFormater.format(new Date(r.created_at.raw)) : '' }</EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  onClick={() => saveResultDispatcher?.({
                    type: 'deleteItem',
                    jdx: [selected.responseState.requestId, r.id.raw]
                  })}
                  color="danger"
                  iconType='trash'
                  aria-label="trash"
                  />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiSplitPanel.Inner>
          <EuiSplitPanel.Inner paddingSize="s">
            <span dangerouslySetInnerHTML={{__html: r.body.snippet || (r.body.raw.slice(0, 128) + (r.body.raw.length > 128 ? '...' : '')) }} />
          </EuiSplitPanel.Inner>
          <EuiSplitPanel.Inner paddingSize="s">
            <EuiFlexGroup wrap responsive={false} gutterSize="xs">
              <EuiFlexItem grow={false}>
                <EuiBadge color="primary">{(r.source || {}).raw}</EuiBadge>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge>{(r.district || {}).raw}</EuiBadge>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color="hollow">{(r.subdistrict || {}).raw}</EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
        )}
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
}

export default MySavedResults;