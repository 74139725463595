import { EuiPagination } from "@elastic/eui";


export default function MyPaging({
  className,
  current,
  resultsPerPage,
  onChange,
  totalPages
}: any) {
  return (
    <EuiPagination
      aria-label="Many pages"
      pageCount={totalPages}
      activePage={current - 1}
      onPageClick={(p) => {
        onChange(p + 1)
      }}
    />
  );
}