import {
  ResultViewProps,
} from "@elastic/react-search-ui-views";
import { Marker, Popup } from 'react-leaflet'
import { LatLng, Icon } from "leaflet";
import { EuiTitle, EuiFlexItem, EuiFlexGroup, EuiText, EuiImage,
  EuiButtonIcon, EuiTextColor } from '@elastic/eui';

function MyMapResult({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  thumbnailField,
  ...rest
}: ResultViewProps) {
  const engineImg: Record<string, string> = {
    "BMA Risk Map : Tedsakit": "/immigration.png",
    "BMA Risk Map : PM 2.5": "/air-quality.png",
    "BMA Risk Map : Flood": "/tide.png",
    "BMA Risk Map : Fire": "/fire.png",
    "BMA Risk Map : Accident": "/fender-bender.png",
    "BMA project": "/project.png",
    "Traffy fondue": "/partners.png",
  }

  const engines: Record<string, Icon> = {
    "BMA Risk Map : Tedsakit": new Icon({
      iconUrl: "immigration.png",
      iconSize: [32, 32],
    }),
    "BMA Risk Map : PM 2.5": new Icon({
      iconUrl: "air-quality.png",
      iconSize: [32, 32],
    }),
    "BMA Risk Map : Flood": new Icon({
      iconUrl: "tide.png",
      iconSize: [32, 32],
    }),
    "BMA Risk Map : Fire": new Icon({
      iconUrl: "fire.png",
      iconSize: [32, 32],
    }),
    "BMA Risk Map : Accident": new Icon({
      iconUrl: "fender-bender.png",
      iconSize: [32, 32],
    }),
    "BMA project": new Icon({
      iconUrl: "project.png",
      iconSize: [32, 32],
    }),
    "Traffy fondue": new Icon({
      iconUrl: "partners.png",
      iconSize: [32, 32],
    }),
  }

  return (
    <Marker position={new LatLng(result.location.raw[1], result.location.raw[0])} key={result.id.raw} icon={engines[result.source.raw]}>
      <Popup>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiImage
              size={32}
              alt=""
              src={engineImg[result.source.raw]}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3>{result.title.raw}</h3>
            </EuiTitle>
            <EuiTitle size="xxs">
              <h5><EuiTextColor color="subdued">{result.source.raw}</EuiTextColor></h5>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType="link"
              aria-label="link"
              color="success"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText dangerouslySetInnerHTML={{__html: result.body.snippet || (result.body.raw.slice(0, 128) + (result.body.raw.length > 128 ? '...' : '')) }} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Popup>
    </Marker>
  );
}
export default MyMapResult;