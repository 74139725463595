import { useState } from "react";
import { EuiPanel, EuiSpacer, EuiButtonEmpty, EuiPopover } from "@elastic/eui";
import { Facet } from "@elastic/react-search-ui";
import MyMultiCheckboxFacet from "./MyMultiCheckboxFacet";
import MyDateFacet from "./MyDateFacet";


export default function MyFilterPopout() {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <EuiPopover
      button={<EuiButtonEmpty iconType="filter"
          onClick={() => setIsFilterVisible(!isFilterVisible)}>
          Filter
        </EuiButtonEmpty>}
      id="filter"
      isOpen={isFilterVisible}
      closePopover={() => setIsFilterVisible(!isFilterVisible)}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiPanel color="transparent" paddingSize="s" style={{ width: 300 }}>
        <Facet
          field="created_at"
          label="Created at"
          view={MyDateFacet}
        />
        <EuiSpacer size="s"  />
        <Facet
          field="district"
          label="District"
          view={MyMultiCheckboxFacet}
        />
        <EuiSpacer size="s" />
        <Facet
          field="subdistrict"
          label="Subdistrict"
          view={MyMultiCheckboxFacet}
        />
        <EuiSpacer size="s"  />
        <Facet
          field="source"
          label="Datasource"
          view={MyMultiCheckboxFacet}
          filterType="any"
        />
      </EuiPanel>
    </EuiPopover>
  );
}