import { LatLng, LatLngBounds } from "leaflet";
import React from "react";



export interface MapBoundContext {
  center: LatLng;
  bounds: LatLngBounds;
  setCenter: React.Dispatch<React.SetStateAction<LatLng>>;
  setBounds: React.Dispatch<React.SetStateAction<LatLngBounds>>;
}

export const MapContext = React.createContext<MapBoundContext | null>(null);