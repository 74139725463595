import { EuiButtonEmpty } from "@elastic/eui";
import { WithSearch } from "@elastic/react-search-ui";
import { useContext } from 'react';
import { ResultsDispatchContext } from '../appReducer';


export default function MySavedResult() {
  const saveResultDispatcher = useContext(ResultsDispatchContext);
  return (
    <WithSearch mapContextToProps={({ current, filters, resultsPerPage, searchTerm, sort,
      requestId, facets, results, resultSearchTerm, totalResults }) => ({  current, filters, resultsPerPage, searchTerm, sort,
      requestId, facets, results, resultSearchTerm, totalResults  })}>
      {({ current, filters, resultsPerPage, searchTerm, sort,
        requestId, facets, results, resultSearchTerm, totalResults }) => <EuiButtonEmpty onClick={() => saveResultDispatcher?.({
          type: 'add',
          result: {
            reqestState: {
              current, filters, resultsPerPage, searchTerm, sort
            },
            responseState: {
              requestId, facets, results, resultSearchTerm, totalResults
            },
            isShown: false,
            isSelected: false
          }
        })}>Save Results</EuiButtonEmpty>
      }
    </WithSearch>
  );
}