import { useEffect, useCallback } from "react";
import { LatLng, CRS, LatLngBounds } from "leaflet";
import { useMapEvents } from 'react-leaflet'

export default function MyMapBound({ filters, current,
    addFilter, removeFilter, setCurrent, setFilter, setSort  }: any) {
  const setBoundsFilter = useCallback((ct:LatLng, bounds:LatLngBounds) => {
    const idx = filters.findIndex((f:any) => f.field === 'location')
    if (idx < 0) {
      setFilter('location', {
        center: `${ct.lat},${ct.lng}`,
        distance: CRS.EPSG4326.distance(ct, bounds.getNorthEast()),
        unit: "m",
      })
    } else if (filters[idx].values[0] && filters[idx].values[0].center !==  `${ct.lat},${ct.lng}`) {
      setFilter('location', {
        center: `${ct.lat},${ct.lng}`,
        distance: CRS.EPSG4326.distance(ct, bounds.getNorthEast()),
        unit: "m",
      })
    }
  }, [filters, setFilter]);
  const map = useMapEvents({
    load: () => {
      setBoundsFilter(map.getCenter(), map.getBounds());
    },
    moveend: () => {
      setBoundsFilter(map.getCenter(), map.getBounds());
    },
    zoomend: () => {
      setBoundsFilter(map.getCenter(), map.getBounds());
    },
  })
  useEffect(() => {
    setBoundsFilter(map.getCenter(), map.getBounds());
  }, [setBoundsFilter, map])
  return (
    <></>
  )
}

