import { LatLng } from "leaflet";
import { MapContainer, TileLayer } from 'react-leaflet';
import { useContext } from "react";
import { ResultsContext } from '../appReducer';
import MySavedMarker from './MySavedMarker';

function MyMapSavedResults() {
  const center = new LatLng(13.7525, 100.494167)
  const results = useContext(ResultsContext);
  return (
    <MapContainer center={center} zoom={13} style={{height: "90vh", width: "100%"}}>
      <TileLayer
        url="http://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c9bd1167a427c6a8ea113decad60cdeb&proj=epsg3857&HD=1"
      />
      { results?.filter((r) => r.isShown).map((r) => r.responseState.results.map((i) => Object.assign({}, i, {
        color: r.color
      }))).flat().map(
        (r) => <MySavedMarker result={r} />)}
    </MapContainer>
  );
}

export default MyMapSavedResults;