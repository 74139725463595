import type { SearchContextState } from "@elastic/search-ui";
import { LatLng } from "leaflet";
import { MapContainer, TileLayer } from 'react-leaflet'
import {
  ResultsViewProps,
} from "@elastic/react-search-ui-views";
import React from "react";
import MyMapBound from './MyMapBound';
import { WithSearch } from "@elastic/react-search-ui";
import { useContext } from "react";
import { ResultsContext } from '../appReducer';
import MySavedMarker from './MySavedMarker';

export type ResultsContainerContext = Pick<SearchContextState, "results">;

function MyMapResults({
  children,
  className,
  ...rest
}: ResultsViewProps & React.HTMLAttributes<HTMLUListElement>) {
  const center = new LatLng(13.7525, 100.494167)
  const savedPins = useContext(ResultsContext);
  return (
    <MapContainer center={center} zoom={13} style={{height: "90vh", width: "100%"}}>
      <WithSearch mapContextToProps={({  filters, current, 
        addFilter, removeFilter, setCurrent, setFilter, setSort }) => ({  filters, current, 
          addFilter, removeFilter, setCurrent, setFilter, setSort })}>
        {MyMapBound}
      </WithSearch>
      <TileLayer
        url="http://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=c9bd1167a427c6a8ea113decad60cdeb&proj=epsg3857&HD=1"
      />
      {children}
      { savedPins?.filter((r) => r.isShown).map((r) => r.responseState.results.map((i) => Object.assign({}, i, {
        color: r.color
      }))).flat().map(
        (r) => <MySavedMarker result={r} key={r.id.raw} />)}
    </MapContainer>
  );
}

export default MyMapResults;