import { Popup, CircleMarker } from 'react-leaflet'
import { LatLng, } from "leaflet";
import { EuiTitle, EuiFlexItem, EuiFlexGroup,
  EuiText, EuiImage, EuiButtonIcon, EuiTextColor } from '@elastic/eui';


export default function MySavedMarker({
  result
}: any) {

  const engineImg: Record<string, string> = {
    "BMA Risk Map : Tedsakit": "/immigration.png",
    "BMA Risk Map : PM 2.5": "/air-quality.png",
    "BMA Risk Map : Flood": "/tide.png",
    "BMA Risk Map : Fire": "/fire.png",
    "BMA Risk Map : Accident": "/fender-bender.png",
    "BMA project": "/project.png",
    "Traffy fondue": "/partners.png",
  }

  return (
    <CircleMarker radius={10} center={new LatLng(result.location.raw[1], result.location.raw[0])} key={result.id.raw} color={result.color} >
      <Popup>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiImage
              size={32}
              alt=""
              src={engineImg[result.source.raw]}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size="s">
              <h3>{result.title.raw}</h3>
            </EuiTitle>
            <EuiTitle size="xxs">
              <h5><EuiTextColor color="subdued">{result.source.raw}</EuiTextColor></h5>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType="link"
              aria-label="link"
              color="success"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText dangerouslySetInnerHTML={{__html: result.body.snippet || (result.body.raw.slice(0, 128) + (result.body.raw.length > 128 ? '...' : '')) }} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Popup>
    </CircleMarker>
  );
}